import { template as template_dbceab8b4d2b4a38b465178c52b9497d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_dbceab8b4d2b4a38b465178c52b9497d(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
